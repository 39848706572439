import {
  ProgramResponse,
  CommunityPostRequest,
  CommunityEditRequest,
  CommentRequest,
} from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { api, Methods } from '../services/api';

export const fetchProgramById = async (programId: number, page: string) =>
  await api<ProgramResponse>(
    Methods.get,
    `/programs/${programId}`,
    {},
    { xPage: page },
  );

export const useProgramById = (programId: number, page: string) =>
  // making programId a string since it can be passed in through query params which will convert it to string
  // making it string here we know that the query key will always have it as string
  // if you try to invalidate it and its a string one place and number in another, it won't match the keys
  useQuery(
    ['programs', { programId: parseInt((programId as unknown) as string, 10) }],
    () => fetchProgramById(programId, page),
  );

export const programPurchase = async (
  programId: number | string,
  xPage: string,
) =>
  await api(
    Methods.post,
    `/programs/${programId}/purchased-programs`,
    {},
    { xPage },
  );

export const markProgramSessionComplete = async (
  programId: number | string,
  sessionId: number | string,
  xPage: string,
) =>
  await api(
    Methods.post,
    `/programs/${programId}/session/${sessionId}`,
    {},
    { xPage },
  );

export const resetProgram = async (programId: number | string) =>
  await api(Methods.post, `/programs/${programId}/reset`);

export const fetchProgramByPage = async (page: string) =>
  api<ProgramResponse[]>(Methods.get, '/programs', {}, { xPage: page });

export const usePrograms = (
  page: string,
  programs?: ProgramResponse[],
  select?: (data: ProgramResponse[]) => ProgramResponse[],
) =>
  useQuery(['programs', { page }], () => fetchProgramByPage(page), {
    initialData: programs,
    select,
  });

/** POST
 * Create
 * Edit
 * Delete
 * */
export const createCommunityPost = async (
  params: CommunityPostRequest,
  programId: number,
) => await api(Methods.post, `/programs/${programId}/post`, params);

export const deletePost = async (programId: number, postId: number) =>
  await api(Methods.delete, `/programs/${programId}/post/${postId}`);

export const editPost = async (
  programId: number,
  postId: number,
  data: CommunityEditRequest,
) => await api(Methods.patch, `/programs/${programId}/post/${postId}`, data);

/** COMMENT
 * Create
 * Edit
 * Delete
 * Like
 * Unlike
 * */
export const addPostComment = async (
  params: CommentRequest,
  programId: number,
  postId: number,
) =>
  await api(
    Methods.post,
    `/programs/${programId}/post/${postId}/comment`,
    params,
  );

// FIXME: change to /post
export const deletePostComment = async (
  programId: number,
  postId: number,
  commentId: number,
) =>
  await api(
    Methods.delete,
    `/programs/${programId}/post/${postId}/comment/${commentId}`,
  );

export const editPostComment = async (
  programId: number,
  postId: number,
  commentId: number,
  data: CommunityPostRequest,
) =>
  await api(
    Methods.patch,
    `/programs/${programId}/post/${postId}/comment/${commentId}`,
    data,
  );

// FIXME: change to /post
export const likePost = async (
  programId: number,
  xPage: string,
  postId: number,
) =>
  await api(
    Methods.post,
    `/programs/${programId}/post/${postId}/like`,
    {},
    { xPage },
  );

// FIXME: change to /post
export const unlikePost = async (
  programId: number,
  xPage: string,
  postId: number,
) =>
  await api(
    Methods.delete,
    `/programs/${programId}/post/${postId}/like`,
    {},
    { xPage },
  );

// FIXME: change to /post
export const likeComment = async (
  programId: number,
  xPage: string,
  postId: number,
  commentId: number,
) =>
  await api(
    Methods.post,
    `/programs/${programId}/post/${postId}/comment/${commentId}/like`,
    {},
    { xPage },
  );

// FIXME: change to /post
export const unlikeComment = async (
  programId: number,
  xPage: string,
  postId: number,
  commentId: number,
) =>
  await api(
    Methods.delete,
    `/programs/${programId}/post/${postId}/comment/${commentId}/like`,
    {},
    { xPage },
  );
