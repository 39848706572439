import { api, Methods } from '../services/api';
import { PageConfigResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { Error } from 'types';

export const getPage = async (xPage: string) =>
  await api<PageConfigResponse>(Methods.get, '/page-config', {}, { xPage });

export const usePageConfig = (page: string) =>
  useQuery<PageConfigResponse, Error>(
    ['pageConfig', { page }],
    () => getPage(page),
    {
      cacheTime: 1000 * 60 * 5,
    },
  );
