import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { buildImageUrl } from 'cloudinary-build-url';
import Text, { TypeVariant } from 'shared/Typography';
import { Gravity, RESIZE_TYPES } from '@cld-apis/utils';

interface TypeProps {
  children: ReactNode | ReactNode[];
}

const Name = ({ children }: TypeProps) => (
  <Text
    variant={TypeVariant.subtitle01}
    as="h6"
    color="var(--color-black-500)"
    phone={TypeVariant.bodyLarge01Bold}
    textAlign="center"
  >
    {children}
  </Text>
);

const Description = ({ children }: TypeProps) => (
  <Text
    variant={TypeVariant.bodyLarge01}
    as="p"
    color="var(--color-black-500)"
    phone={TypeVariant.body01}
    textAlign="center"
  >
    {children}
  </Text>
);

interface Props {
  image: string;
  name: string;
  testimonial: string;
}

const Testimonial = ({ image, name, testimonial }: Props) => {
  const imageSrc = buildImageUrl(image, {
    transformations: {
      resize: {
        type: RESIZE_TYPES.THUMBNAIL,
        width: 400,
        height: 400,
      },
    },
  });

  return (
    <Container>
      <ImageWrap>
        <Image src={imageSrc} width={200} height={200} />
      </ImageWrap>
      <InfoContainer>
        <Name>{name}</Name>
        <Description>"{testimonial}"</Description>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24);
`;

const ImageWrap = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 999px;
  line-height: 0;
  overflow: hidden;
  background: var(--color-gray-100);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: 150px;
    height: 150px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16);
  padding: 0 var(--spacing-16);
`;

export default Testimonial;
