import {
  EventResponse,
  LiveWorkoutResponse,
  PageSubscriptions,
  PurchasedEventResponse,
  SubscriptionResponse,
  SubscriptionStatus,
  UserMetaDataResponse,
  WorkoutResponse,
  PurchasedProgramResponse,
  PurchasedClassesResponse,
} from '@solin-fitness/types';

export const hasSubscription = (
  metadata: UserMetaDataResponse | undefined,
  subscriptions: PageSubscriptions[],
  authenticated: boolean,
): [boolean, SubscriptionResponse | undefined] => {
  if (!metadata || !authenticated) {
    return [false, undefined];
  }

  const pageSubscriptions = subscriptions.map((val) => val.priceId);
  const subscription = metadata.subscriptions.find((val) =>
    pageSubscriptions.includes(val.priceId),
  );
  if (subscription) {
    if (
      [
        SubscriptionStatus.active,
        SubscriptionStatus.trialing,
        SubscriptionStatus.pastDue,
      ].includes(subscription.status)
    ) {
      const end = subscription.currentPeriodEnd * 1000;
      const curr = new Date().getTime();
      // if was cancelled and billing period end has pasted
      if (subscription.cancelAtPeriodEnd && curr > end) {
        return [false, undefined];
      }
      return [true, subscription];
    }
  }
  return [false, undefined];
};

export const hasPurchasedClass = (
  metadata: UserMetaDataResponse | undefined,
  data: LiveWorkoutResponse | WorkoutResponse | EventResponse | undefined,
  type: 'liveWorkout' | 'workoutDrop' | 'event',
  authenticated: boolean,
): boolean => {
  if (!metadata || !authenticated || type !== 'liveWorkout') {
    return false;
  }

  if (!data) {
    return false;
  }

  const userPurchasedClass = metadata.purchasedClasses.find(
    (val) => val.liveWorkout.id === data.id,
  );
  return !!userPurchasedClass;
};

export const hasPurchasedLiveWorkout = (
  purchasedLiveWorkouts: PurchasedClassesResponse[],
  liveWorkoutId: number,
): boolean =>
  !!purchasedLiveWorkouts.find((data) => data.liveWorkout.id === liveWorkoutId);

export const hasPurchasedEvent = (
  purchasedEvents: PurchasedEventResponse[],
  eventId: number,
): boolean => !!purchasedEvents.find((data) => data.event.id === eventId);

export const hasPurchasedProgram = (
  purchasedProgram: PurchasedProgramResponse[],
  programId: number,
): boolean => !!purchasedProgram.find((data) => data.program.id === programId);
